"use strict";

/*globals Kobo,ko*/
// Allows you to use knockout components outside of an existing binding context.
// This is not required if you are using knockout components inside of an existing binding context
// (aka inside of an element that is bound using ko.applyBindings).
Kobo.Gizmo.KnockoutArea = function (el) {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("KnockoutArea");

  this.init = function () {
    ko.applyBindings({}, el);
  };

  this.init();
};

Kobo.Gizmo.KnockoutArea.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);