"use strict";

/*global Kobo,window*/
// singleton
Kobo._purchaseBroker = function () {
  'use strict';

  var init,
      initCheckout,
      onInitCheckout,
      THREE_DS_IFRAME_ID = 'threeDsecFrame',
      THREE_DS_IFRAME_WIDTH = 450,
      THREE_DS_IFRAME_HEIGHT = 400;

  initCheckout = function initCheckout(eventName, data) {
    onInitCheckout({
      actionUrl: data.detail.actionUrl,
      ageVerificationRequired: data.detail.ageVerificationRequired
    });
  };

  onInitCheckout = function onInitCheckout(data) {
    if (Kobo._AgeVerification.isRequired(data.ageVerificationRequired)) {
      data.caller = "purchaseBroker::initiateCheckout";

      Kobo._mediator.fire('ageVerificationCheck', data);
    } else {
      if (data.actionUrl) {
        Kobo._tracker.sendResultsSynchronously();

        window.location.href = data.actionUrl;
      } else {
        throw "No url given for checkout";
      }
    }
  };

  init = function init() {
    Kobo._mediator.register('purchaseBroker::initiateCheckout');

    Kobo._mediator.subscribe('purchaseBroker::initiateCheckout', initCheckout);

    Kobo._mediator.register('ageVerificationCheck');
  };

  init();
  return {
    /**
     * Builds an iframe to show the UI for 3D secure handling of credit cards
     *
     * 3D Secure is an additional security measure for credit card processing.
     * Users are required to enter a one-time-use PIN, in addition to their
     * credit card number and CVV.
     *
     * See Verified by Visa or MasterCard SecureCode for implementations.
     *
     * The content in the iframe is a black box and beyond the control of client code.
     *
     * @param threeDSecURL The url to use for the iframe, typically provided by a payment gateway
     * @return An iframe HTML element, with all required attributes set correctly
     */
    buildThreeDSecIFrame: function buildThreeDSecIFrame(threeDSecURL) {
      var $threeDSecIframe = Kobo.$('<iframe>').attr({
        'id': THREE_DS_IFRAME_ID,
        'name': THREE_DS_IFRAME_ID,
        'src': threeDSecURL,
        'width': THREE_DS_IFRAME_WIDTH,
        'height': THREE_DS_IFRAME_HEIGHT,
        'scrolling': 'auto',
        'frameborder': '0',
        'onLoad': 'receivePINMsg()'
      });
      return $threeDSecIframe.get(0);
    }
  };
}();