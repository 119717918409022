"use strict";

/*global Kobo, ko */
(function () {
  ko.components.register('kobo-label', {
    viewModel: function viewModel(options) {
      var settings = Kobo.$.extend({
        id: null,
        required: false,
        text: ''
      }, options);
      this.id = settings.id;
      this.required = settings.required;
      this.text = settings.text;
    },
    template: Kobo.$('#kobo-label-template').html()
  });
})();