"use strict";

ko.bindingHandlers.navbarSelected = ko.bindingHandlers.navbarSelected || {
  update: function update(element, valueAccessor) {
    var observable = ko.unwrap(valueAccessor());
    var $element = Kobo.$(element);

    var keyUpHandler = function keyUpHandler(event) {
      if (event.keyCode === Kobo.KeyCodes.ESC) {
        observable.selected(null);
      }
    };

    if (observable.selected && observable.selected() !== null) {
      Kobo.$(document).on('keyup', keyUpHandler);
    } else {
      Kobo.$(document).off('keyup', keyUpHandler);
    }
  }
};