"use strict";

Kobo.Utilities.assignToNamespace('Kobo.Utilities.DomUtils', {
  isScrolledIntoView: function isScrolledIntoView($element) {
    var $window = $(window);
    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();
    var elemTop = $element.offset().top;
    var elemBottom = elemTop + $element.height();
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  },
  isWithinView: function isWithinView($element) {
    var $window = $(window);
    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();
    var elemTop = $element.offset().top;
    var elemBottom = elemTop + $element.height();
    return elemBottom > docViewTop && elemTop < docViewBottom;
  }
});