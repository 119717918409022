"use strict";

ko.bindingHandlers.ariaInvalid = ko.bindingHandlers.ariaInvalid || {
  update: function update(element, valueAccessor) {
    var observable = valueAccessor(),
        $element = Kobo.$(element);

    if (observable.isModified && observable.isModified() && observable.isValid && !observable.isValid()) {
      $element.attr('aria-invalid', 'true');
    } else {
      $element.prop('aria-invalid', false);
    }
  }
};