"use strict";

/*global Kobo, window*/

/**
 * Common functions used in display of a user's library.
 */
Kobo.Library = {}; // stub to allow proper namespacing

Kobo.Library.SortDisplay = function (el, options) {
  'use strict';

  var init, updateDisplay;
  /*jslint unparam: true*/

  updateDisplay = function updateDisplay(event, data) {
    var className = 'gizmo-initially-hidden';

    if (data.detail.libraryView === options.hideInView) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += ' ' + className;
      }
    } else {
      if (el.classList) {
        el.classList.remove(className);
      } else {
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    }
  };
  /*jslint unparam: false*/


  init = function init() {
    Kobo._mediator.register('libraryLayout::viewChanged');

    Kobo._mediator.register('libraryLayout::viewInitialised');

    Kobo._mediator.subscribe('libraryLayout::viewInitialised libraryLayout::viewChanged', updateDisplay);
  };

  init();
};