"use strict";

/*global Kobo,document, DynamicConfiguration*/
// singleton
Kobo._toaster = function () {
  'use strict';

  var $pageHook,
      $toastContainer,
      $innerToast,
      $closeButton,
      $textContainer,
      $tealHeaderHook,
      $innerWrapper,
      closeButtonLabel = DynamicConfiguration.resourceStrings.okGotIt,
      initialiseToast,
      hideToast,
      showToast,
      timer,
      defaultOptions,
      TOASTER_CLASS = 'toast-wrap',
      DURATION = 3000,
      buildToastContainer; // build the toaster div and select hook. Rich header does not provide proper hook and is not fixed so Page hook is defaulted to be on the body tag.

  $innerWrapper = Kobo.$('<div class="inner-wrapper" />');
  $textContainer = Kobo.$('<p />');
  $innerToast = Kobo.$('<div class = "toast message-box" />');
  $closeButton = Kobo.$('<span class="dismiss-link">' + closeButtonLabel + '<span/>'); // add element baseclass and insert in appropriate place    

  $innerToast.append($innerWrapper);
  $innerWrapper.append($textContainer);

  buildToastContainer = function buildToastContainer() {
    $toastContainer = Kobo.$('#toast-wrapper-container');
    $toastContainer.addClass(TOASTER_CLASS);
    $toastContainer.append($innerToast);
  }; // Set default options


  defaultOptions = {
    'duration': DURATION,
    'pageHook': 'body'
  };

  initialiseToast = function initialiseToast(options) {
    $tealHeaderHook = Kobo.$('#kobo-web-header');
    buildToastContainer();

    if ($tealHeaderHook.length === 1) {
      $pageHook = $tealHeaderHook;
      $pageHook.append($toastContainer);
    } else if (options.pageHook != 'body') {
      $pageHook = Kobo.$(options.pageHook);
      $pageHook.append($toastContainer);
      $toastContainer.addClass('rich-header-toast');
    } else {
      $toastContainer.addClass('rich-header-toast');
    }
  };

  hideToast = function hideToast(e) {
    // eslint-disable-line no-unused-vars 
    $textContainer[0].innerHTML = "";
    $toastContainer.removeClass('popdown');
  };

  showToast = function showToast(eventname, config) {
    var options;

    if (config.detail) {
      options = config.detail;
    }

    options = Kobo.$.extend({}, defaultOptions, options); // We only need to append the toast element if it's going to be used, so hold off the $pageHook.append call until it's called.
    // This also allows easier testing, as we can specify the location it appears using pageHook.
    // Only do it once, so check if it's already been appended. 

    if (!$toastContainer || !Kobo.$.contains(document, $toastContainer[0])) {
      initialiseToast(options);
    }

    if (options.position) {
      $toastContainer.addClass(options.position);
    } // reset text classes


    $textContainer.attr('class', '');
    $innerToast.attr('class', 'toast message-box');

    if (options.message) {
      // Set up message
      $textContainer.html(options.message); // Set up display context

      switch (eventname.type) {
        case 'toast::success':
          $innerToast.addClass('success');
          $textContainer.addClass('success-text');
          clearTimeout(timer);
          timer = setTimeout(hideToast, options.duration);
          break;

        case 'toast::error':
          $innerToast.addClass('error');
          $textContainer.addClass('error-text');
          $textContainer.append($closeButton);
          $closeButton.on('click', hideToast);
          break;

        case 'toast::info':
          clearTimeout(timer);
          timer = setTimeout(hideToast, options.duration);
          break;

        case 'toast::warning':
          $innerToast.addClass('warning');
          $textContainer.addClass('warning-text');
          $textContainer.append($closeButton);
          clearTimeout(timer);
          timer = setTimeout(hideToast, options.duration);
          break;
      } // Display toast


      $toastContainer.addClass('popdown');
    } else {
      Kobo.log('Toast requires message({"String"}) argument');
    }
  }; // register to events


  Kobo._mediator.register('toast::info');

  Kobo._mediator.register('toast::success');

  Kobo._mediator.register('toast::error');

  Kobo._mediator.register('toast::warning'); // subscribe to events


  Kobo._mediator.subscribe('toast::info', showToast);

  Kobo._mediator.subscribe('toast::success', showToast);

  Kobo._mediator.subscribe('toast::error', showToast);

  Kobo._mediator.subscribe('toast::warning', showToast);
}();