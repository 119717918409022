"use strict";

ko.bindingHandlers.ariaDescribedbyIf = ko.bindingHandlers.ariaDescribedbyIf || {
  update: function update(element, valueAccessor) {
    var observable = valueAccessor(),
        $element = Kobo.$(element);

    if (ko.isObservable(observable.condition) && observable.condition() || !ko.isObservable(observable.condition) && observable.condition) {
      $element.attr('aria-describedby', observable.value);
    } else {
      $element.removeAttr('aria-describedby');
    }
  }
};