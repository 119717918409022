"use strict";

/*global Kobo */
(function ($) {
  $.event.props.push('targetTouches');
  $.event.props.push('touches');

  $.fn.swipe = function (options) {
    var defaults = {
      threshold: 30,
      tolerance: 10,
      preventDefaultEvents: true,
      swipeLeft: function swipeLeft() {},
      swipeRight: function swipeRight() {},
      swipeUp: function swipeUp() {},
      swipeDown: function swipeDown() {}
    };
    $.extend(defaults, options);

    var calcDelta = function calcDelta(elem, axis) {
      var dimension = {
        x: 'width',
        y: 'height'
      }[axis],
          before = elem.data('swipe.originalCoord')[axis],
          after = elem.data('swipe.finalCoord')[axis],
          total = elem[dimension]();
      return (before - after) / total * 100;
    };

    if (defaults.tolerance > defaults.threshold) return false;
    if (!this) return false;
    $(this).on('touchstart', function (event) {
      if (defaults.preventDefaultEvents) event.preventDefault();
      $(this).data('swipe.finalCoord', {});
      $(this).data('swipe.cancel', event.touches.length > 1);
      $(this).data('swipe.originalCoord', {
        x: event.targetTouches[0].pageX,
        y: event.targetTouches[0].pageY
      });
    });
    $(this).on('touchmove', function (event) {
      if ($(this).data('swipe.cancel')) return true;
      if (defaults.preventDefaultEvents) event.preventDefault();
      $(this).data('swipe.cancel', event.touches.length > 1);
      $(this).data('swipe.finalCoord', {
        x: event.targetTouches[0].pageX,
        y: event.targetTouches[0].pageY
      });
    });
    $(this).on('touchend', function (event) {
      if ($(this).data('swipe.cancel')) return true;
      if (defaults.preventDefaultEvents) event.preventDefault();
      var changeX = calcDelta($(this), 'x'),
          changeY = calcDelta($(this), 'y');

      if (Math.abs(changeX) > defaults.threshold && Math.abs(changeY) < defaults.tolerance) {
        if (changeX > 0) {
          options.swipeLeft();
        } else {
          options.swipeRight();
        }
      } else if (Math.abs(changeY) > defaults.threshold && Math.abs(changeX) < defaults.tolerance) {
        if (changeY > 0) {
          options.swipeUp();
        } else {
          options.swipeDown();
        }
      }
    });
    $(this).on('touchcancel', function () {
      $(this).data('swipe.originalCoord', {});
      $(this).data('swipe.cancel', false);
    });
    return this;
  };
})(Kobo.$);