"use strict";

/*globals Kobo, ko*/
ko.bindingHandlers.rippleContainer = ko.bindingHandlers.rippleContainer || {
  init: function init(element, valueAccessor) {
    var _ko$unwrap = ko.unwrap(valueAccessor()),
        rippleClassName = _ko$unwrap.rippleClassName;

    Kobo.event.setRippleEffectEvent(element, rippleClassName);
  }
};