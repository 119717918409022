"use strict";

(function () {
  Kobo.Utilities.assignToNamespace('Kobo.Controls.Dialog.DialogVM', DialogVM);

  function DialogVM(options) {
    var self = this;
    var settings = Kobo.$.extend({
      title: null,
      content: null,
      buttons: [new Kobo.Controls.Dialog.ButtonVM({
        label: DynamicConfiguration.resourceStrings.ok
      })]
    }, options);
    this.title = ko.observable(settings.title);
    this.content = ko.observable(settings.content);
    this.buttons = settings.buttons;
    this.notifyClosingEvent = new ko.subscribable();

    this.notifyClosing = function () {
      self.notifyClosingEvent.notifySubscribers();
    };

    $.each(this.buttons, function (index, button) {
      if (button.closeOnClick) {
        button.notifyClickEvent.subscribe(self.notifyClosing);
      }
    });
  }
})();