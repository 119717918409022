"use strict";

/*globals Kobo*/

/**
 * Don't call this directly, use Kobo.Utilities.storage which will
 * handle using window.localStorage instead if it is available
 */
(function () {
  'use strict';

  Kobo.LocalMemoryStorage = {
    setItem: function setItem(key, value) {
      this[key] = value;
    },
    getItem: function getItem(key) {
      if (this[key] === undefined) {
        // to conform with localStorage API, null must be returned if the key does not exist
        return null;
      }

      return this[key];
    },
    removeItem: function removeItem(key) {
      delete this[key];
    }
  };
})();