"use strict";

ko.bindingHandlers.toggleToolTip = ko.bindingHandlers.toggleToolTip || {
  init: function init(element, valueAccessor) {
    var observable = ko.unwrap(valueAccessor()),
        $element = Kobo.$(element),
        $triggerElement = $element.find('button');
    $element.mouseover(function () {
      observable.showContent(true);
    });
    $element.mouseout(function () {
      if (!(observable.isClicked && observable.isClicked())) {
        observable.showContent(false);
      }
    });
    $triggerElement.focus(function () {
      observable.showContent(true);
    });
    $triggerElement.blur(function () {
      observable.showContent(false);
      observable.isClicked(false);
    });
    $triggerElement.click(function (event) {
      event.preventDefault();
      observable.isClicked(!observable.isClicked());
      observable.showContent(observable.showContent() && observable.isClicked());
    });
  },
  update: function update(element, valueAccessor) {
    var observable = ko.unwrap(valueAccessor());

    var keyUpHandler = function keyUpHandler(event) {
      if (event.keyCode === Kobo.KeyCodes.ESC) {
        observable.showContent(false);
        observable.isClicked(false);
      }
    };

    if (observable.showContent && observable.showContent() || observable.isClicked && observable.isClicked()) {
      Kobo.$(document).on('keyup', keyUpHandler);
    } else {
      Kobo.$(document).off('keyup', keyUpHandler);
    }
  }
};