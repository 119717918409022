"use strict";

/*global Kobo*/

/**
 * A Kobo web page goes through several stages in its life cycle.
 * This object provides a way to instrument and query the stages of the life cycle.
 * Used in end-to-end automation tests.
 *
 * Supported events are:
 *
 * koboJsReady
 * Trigger: When Kobo JavaScript finishes loading.
 * Implication: For most pages, this means that the page is ready for interaction.
 *     Images and iframes may not have finished loading. AJAX calls may not have completed.
 * One-time event?: Yes.
 * Applicable pages: All.
 *
 * pageReady
 * Trigger: When Kobo JavaScript finishes loading, and initial AJAX calls have completed.
 * Implication: For most pages, this means that the page is ready for interaction. This happens after koboJsReady.
 *     Images and iframes may not have finished loading. There may still be ongoing AJAX requests from non-Kobo scripts.
 * One-time event?: Yes.
 * Applicable pages: All.
 *
 * checkoutReady
 * Trigger: When all checkout data is received from the server.
 * Implication: Checkout page is ready for interaction. This happens after pageReady.
 * One-time event?: Yes.
 * Applicable pages: Checkout.
 *
 * ajaxDone
 * Trigger: When all AJAX calls have completed.
 *     If there are ongoing AJAX calls when subscribing to this event, the subscriber will be notified
 *     when all those calls complete. This includes any additional AJAX calls that may be made
 *     synchronously from handlers of those AJAX calls.
 *     If there are no ongoing AJAX calls when subscribing to this event, the subscriber will be notified
 *     the next time there is AJAX activity and that activity completes.
 * Implication: There are no more active AJAX requests from Kobo code.
 *     There may still be ongoing AJAX requests from non-Kobo scripts like Visa Checkout and Ratings and Reviews.
 * One-time event?: No.
 * Applicable pages: Any that involves AJAX.
 * 
 */
var LifeCycle = function LifeCycle(dispatcher, jQuery, globals) {
  'use strict';

  var self = this,
      init,
      setupHandlers,
      OneTimeEvent = function OneTimeEvent() {
    // use promises to handle ugly async notifications
    this.promise = new jQuery.Deferred();
  },
      events = {
    koboJsReady: new OneTimeEvent(),
    pageReady: new OneTimeEvent(),
    checkoutReady: new OneTimeEvent(),
    initialAjaxDone: new OneTimeEvent(),
    ajaxDone: {}
  },
      onKoboJsReady,
      onAjaxDone,
      onInitialCheckoutDataLoaded;
  /*** Public interface ***/


  self.subscribe = function (eventName, callback) {
    var lifeCycleEvent = events[eventName];

    if (!lifeCycleEvent) {
      throw 'Unrecognized life cycle event';
    }

    if (lifeCycleEvent.promise) {
      lifeCycleEvent.promise.done(function () {
        // execute callback after other event-handling code finishes
        setTimeout(callback, 0);
      });
    } else {
      lifeCycleEvent.callback = callback;
    }
  };

  self.destroy = function () {
    jQuery(document).off('ajaxComplete', onAjaxDone); // this is an undocumented way to remove handlers for ajaxComplete, so it may not work in future

    dispatcher.unSubscribe('regularCheckout::dataLoaded', onInitialCheckoutDataLoaded);
    dispatcher.unSubscribe('billingInfo::update', events.checkoutReady.promise.resolve);
    dispatcher.unSubscribe('gizmosInitialized', onKoboJsReady);
  };
  /*** Private functions ***/


  init = function init() {
    setupHandlers();

    if (globals.lifeCycleFlag) {
      self.subscribe(globals.lifeCycleFlag.event, globals.lifeCycleFlag.callback);
    }
  };

  setupHandlers = function setupHandlers() {
    dispatcher.subscribe('gizmosInitialized', onKoboJsReady);
    dispatcher.subscribe('regularCheckout::dataLoaded', onInitialCheckoutDataLoaded);
    jQuery(document).ajaxComplete(onAjaxDone);
    jQuery.when(events.koboJsReady.promise, events.initialAjaxDone.promise).done(events.pageReady.promise.resolve);
  };

  onKoboJsReady = function onKoboJsReady() {
    var pendingAjaxRequests = jQuery.active;
    events.koboJsReady.promise.resolve(); // Resolve initialAjaxDone if there are no AJAX calls on page load
    // If there are/were AJAX calls on page load, onAjaxDone() will resolve initialAjaxDone

    if (pendingAjaxRequests === 0) {
      events.initialAjaxDone.promise.resolve();
    }
  };

  onInitialCheckoutDataLoaded = function onInitialCheckoutDataLoaded() {
    // The checkout page makes 2 AJAX calls to fully load
    // The first response triggers regularCheckout::dataLoaded
    // The second triggers billingInfo::update
    // Here, the first response has happened and we subscribe to the second
    dispatcher.subscribe('billingInfo::update', events.checkoutReady.promise.resolve);
  };

  onAjaxDone = function onAjaxDone() {
    // jQuery.active will never be 0 when ajaxComplete() executes.
    // The check for any pending requests should be done after that, hence setTimeout
    setTimeout(function () {
      var noPendingAjax = jQuery.active === 0,
          ajaxDone = events.ajaxDone;

      if (noPendingAjax) {
        if (ajaxDone.callback) {
          ajaxDone.callback();
          ajaxDone.callback = null;
        } // if this the first time AJAX calls complete, resolve initialAjaxDone


        if (events.initialAjaxDone.promise.state() === 'pending') {
          events.initialAjaxDone.promise.resolve();
        }
      }
    }, 0);
  };

  init();
};

window.lifeCycle = new LifeCycle(Kobo._mediator, Kobo.$, window);