"use strict";

/*global Kobo,window*/
// TODO: Move mediator events to this file
Kobo.event = {
  fireDeviceEvent: function fireDeviceEvent(url) {
    "use strict";

    if (url) {
      window.location.href = url;
    } else {
      Kobo.log('error: url was not specified');
    }
  },
  setRippleEffectEvent: function setRippleEffectEvent(targetContainer, targetEventClass) {
    "use strict";

    self.checkParents = function () {
      return $(event.target).closest('.' + targetEventClass).length > 0 && $(event.target).closest('.' + targetEventClass)[0].classList.contains(targetEventClass);
    };

    targetContainer.addEventListener('mousedown', function (event) {
      if (event.target.classList.contains(targetEventClass) || self.checkParents()) {
        var ripple = null,
            eventOffSetX = event.offsetX,
            eventOffSetY = event.offsetY;
        ripple = event.target.querySelector(".button-ripple-effect");

        if (ripple) {
          var ripples = document.querySelectorAll(".button-ripple-effect");
          ripples.forEach(function (ripple) {
            ripple.remove();
          });
        }

        ripple = document.createElement('div');
        ripple.classList.add('button-ripple-effect');
        ripple.style.left = eventOffSetX + "px";
        ripple.style.top = eventOffSetY + "px";
        event.target.appendChild(ripple);
        setTimeout(function () {
          ripple.remove();
        }, 700);
      }
    });
  }
};