"use strict";

/*global Kobo,ko,$*/
Kobo.Bindings = function () {
  "use strict";

  var init, setupHandlers;

  setupHandlers = function setupHandlers() {
    Kobo.$body.on("click", ".preventDefault", function (evt) {
      evt.preventDefault();
    });
    Kobo.$body.on("click", ".stopPropagation", function (evt) {
      evt.stopPropagation();
    });
  };

  init = function init() {
    setupHandlers();
  };

  init();
};

Kobo._bindings = new Kobo.Bindings();