"use strict";

window.resources = {
  base: function base() {
    if (window.DynamicConfiguration && DynamicConfiguration.resourceStrings) {
      return window.DynamicConfiguration.resourceStrings;
    } else if (window.resourceStrings) {
      return window.resourceStrings;
    } else {
      throw new Error('The required base object is not defined.');
    }
  },
  getString: function getString() {
    var section = window.resources.base();

    if (arguments.length > 0) {
      for (var i = 0; i < arguments.length - 1; i++) {
        var key = arguments[i];

        if (section[key]) {
          section = section[key];
        } else {
          return null;
        }
      }

      return section[arguments[arguments.length - 1]];
    }

    throw new Error('A key must be provided as an argument.');
  }
};