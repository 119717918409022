"use strict";

/*global Kobo*/
Kobo.Tracker = function (jQuery) {
  "use strict";

  var clickTrackServer = "/TrackClicks",
      storageKey = 'koboTrackingBuffer',
      trackAttribute = 'data-track-info',
      storage = Kobo.Utilities.storage,
      log,
      getInterval = function getInterval() {
    if (log.length <= 50) {
      return 5000;
    }

    if (log.length <= 100) {
      return 2500;
    }

    return 1000;
  },
      aggregateTrackInfo = function aggregateTrackInfo(leafElement) {
    var ancestorsWithTrackInfo = jQuery(leafElement).parents('[' + trackAttribute + ']').get(),
        aggregate = {}; // Sort ancestors so that the root is first and the deepest element is last.
    // This ensures that if the same key is used by different elements,
    // descendant values will overwrite ancestor ones.

    ancestorsWithTrackInfo.reverse(); // Skipping the check if leafElement has data-track-info;
    // jQuery.extend handles the case if it is undefined

    ancestorsWithTrackInfo.push(leafElement);
    jQuery.each(ancestorsWithTrackInfo, function () {
      jQuery.extend(aggregate, JSON.parse(jQuery(this).attr(trackAttribute) || '{}'));
    });
    return aggregate;
  },
      buildMessage = function buildMessage(event) {
    var message = {};
    var mouseClick = ['click', 'middle_click', 'right_click'];

    if (event.button >= 0 && event.button <= 2) {
      message.event = mouseClick[event.button];
    } else if (event.type === 'impression') {
      message.event = event.detail.event;
      message.carouselName = event.detail.carouselName;
    } else {
      message.event = 'undefined';
    }

    message.element = event.target.tagName.toLowerCase();
    message.location = window.location.href;
    var parentA = jQuery(event.target).closest('a');

    if (parentA.length) {
      message.destination = parentA[0].href;
    }

    jQuery.extend(message, aggregateTrackInfo(event.target));
    return message;
  },
      recordMessage = function recordMessage(event) {
    var message = buildMessage(event);
    log.push(JSON.stringify(message));
    storage.setItem(storageKey, JSON.stringify(log));
  },
      dequeueLog = function dequeueLog(maxMessages) {
    var count = Math.min(maxMessages, log.length),
        message = [],
        i;

    if (log.length === 0) {
      return null;
    }

    for (i = 0; i < count; i++) {
      message[i] = log.shift();
    }

    return JSON.stringify(message);
  },
      onResultsSent = function onResultsSent() {
    // sent messages were dequeued from log; all that remains is to update persistent storage 
    storage.setItem(storageKey, JSON.stringify(log));
  },
      buildRequestBody = function buildRequestBody(results) {
    return {
      method: "POST",
      url: clickTrackServer,
      data: results,
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      success: onResultsSent,
      error: function error() {}
    };
  },
      sendResults = function sendResults() {
    var results = dequeueLog(50);

    if (results && window.location.protocol !== "file:") {
      jQuery.ajax(buildRequestBody(results)); // FIXME if AJAX request is successful but success handler never gets a chance to execute, messages are not removed from localStorage
    }
  },
      sendResultsSynchronously = function sendResultsSynchronously() {
    var results = dequeueLog(50),
        request;

    if (results && window.location.protocol !== "file:") {
      request = buildRequestBody(results);
      request.async = false;
      jQuery.ajax(request);
    }
  },
      isTrackable = function isTrackable(element) {
    var trackableElements = 'a, button, input, [data-force-track]';
    return jQuery(element).is(trackableElements) || jQuery(element).parents(trackableElements).length;
  },
      onClick = function onClick(event) {
    // skip left click for mouse down events to avoid duplicates
    if (isTrackable(event.target) && !(event.type === "mousedown" && event.button === 0)) {
      recordMessage(event);
    }
  },
      onImpression = function onImpression(event) {
    recordMessage(event);
  },
      canTrack = function canTrack() {
    // showing how to respect the doNotTrack initiative.
    return !window.navigator.doNotTrack || window.navigator.doNotTrack !== "yes";
  },
      init,
      destroy,
      intervalID;

  init = function init() {
    log = JSON.parse(storage.getItem(storageKey)) || [];

    if (canTrack()) {
      // We are using a native event here to support useCapture.
      // This is to fix any issues where the tracking data could be
      // aggregated incorrectly due to dom elements being removed/changed
      // by the time the event bubbles all the way up to the body.
      document.body.addEventListener('click', onClick, true);
      document.body.addEventListener('mousedown', onClick, true);
      document.body.addEventListener('impression', onImpression, true);
      intervalID = window.setInterval(sendResults, getInterval());
    }
  };

  destroy = function destroy() {
    window.clearInterval(intervalID);
    document.body.removeEventListener('click', onClick, true);
    document.body.removeEventListener('mousedown', onClick, true);
    document.body.removeEventListener('impression', onClick, true);
    storage.removeItem(storageKey);
  };

  init(); // start the tracker

  return {
    /**
     * Aggregates data-track-info attributes of all elements between the root <html> element and leafElement.
     *
     * If the same key is used by more than one element, the descendant's value will take precedence.
     * Example:
     * <div data-track-info="{"a": 1, "b": 2}">
     *     <div data-track-info="{"a": 3}"></div>
     * <div>
     *
     * will result in the aggregate {"a": 3, "b": 2}
     */
    aggregateTrackInfo: aggregateTrackInfo,
    getTrackAttribute: function getTrackAttribute() {
      return trackAttribute;
    },
    sendResultsSynchronously: sendResultsSynchronously,
    destroy: destroy,
    // Exposed for unit testing purposes. Do not use!
    _clickTrackServer: clickTrackServer,
    _onClick: onClick
  };
};