"use strict";

if (typeof Kobo.GoogleAnalytics === "undefined") {
  Kobo.GoogleAnalytics = {
    trackEvent: function trackEvent(category, action, label) {
      if (category != undefined && category != "") {
        ga('send', 'event', category, action, label);
      }
    },
    setupHandlers: function setupHandlers() {
      Kobo.$("[data-ga-track]").on('click', function () {
        var gaData = Kobo.$(this).data('ga-track');
        Kobo.GoogleAnalytics.trackEvent(gaData.category, gaData.action, gaData.label);
      });
    },
    sendGoogleAnalyticsTransaction: function sendGoogleAnalyticsTransaction(transaction, item) {
      ga('require', 'ecommerce', 'ecommerce.js');
      ga('ecommerce:addTransaction', {
        'id': transaction.PurchaseId,
        'affiliation': transaction.Affiliation,
        'revenue': transaction.Total,
        'shipping': transaction.ShippingTotal,
        'tax': transaction.TaxTotal
      });
      ga('ecommerce:addItem', {
        'id': transaction.PurchaseId,
        'name': item.Name,
        'sku': item.ProductId,
        'category': item.Category,
        'price': item.Price,
        'quantity': item.Quantity
      });
      ga('ecommerce:send');
    },
    init: function init() {
      this.setupHandlers();
    }
  };
  Kobo.GoogleAnalytics.init();
}