"use strict";

/*global Kobo*/
// singleton
Kobo._modal = function () {
  'use strict';

  var existsModal,
      createModal,
      accessibility,
      onWindowResize,
      constrainContent,
      hideModal,
      showModal,
      isOpen,
      parseOptions,
      $modalDiv,
      $modalContentDiv,
      $modalDiv_extra,
      $modalContentDiv_extra,
      stopPropagation,
      MODAL_ID = 'modal',
      EXTRA_MODAL_ID = 'extra_modal',
      $previousActiveElement = null,
      MODAL_CONTENT_ID = 'modal-content',
      EXTRA_MODAL_CONTENT_ID = 'extra_modal-content',
      SHOW_CLASS = 'show-modal',
      createModalWithExistModal;

  existsModal = function existsModal() {
    return $modalDiv;
  };

  createModal = function createModal() {
    var clickTrackInfo = '';

    if (Kobo._tracker) {
      clickTrackInfo = Kobo._tracker.getTrackAttribute() + '=\'{ "section": "modal" }\'';
    }

    if (existsModal()) {
      return;
    }

    $modalDiv = Kobo.$('<div id="' + MODAL_ID + '" ' + clickTrackInfo + ' tabindex="-1"></div>');
    $modalContentDiv = Kobo.$('<div id="' + MODAL_CONTENT_ID + '" role="dialog" aria-modal="true"></div>');
    $modalDiv.append($modalContentDiv); // Modal needs to be the first child in the body tag
    // so that shift+tab takes you into the browser chrome

    Kobo.$body.prepend($modalDiv);
  };

  createModalWithExistModal = function createModalWithExistModal(appendTarget) {
    var clickTrackInfo = '';

    if (Kobo._tracker) {
      clickTrackInfo = Kobo._tracker.getTrackAttribute() + '=\'{ "section": "modal" }\'';
    }

    $modalDiv_extra = Kobo.$('<div id="' + EXTRA_MODAL_ID + '" ' + clickTrackInfo + ' tabindex="0"></div>');
    $modalContentDiv_extra = Kobo.$('<div id="' + EXTRA_MODAL_CONTENT_ID + '" role="dialog" aria-modal="true"></div>');
    $modalDiv_extra.append($modalContentDiv_extra);
    existsModal().find(appendTarget).append($modalDiv_extra);
  };

  accessibility = {
    $hiddenSiblings: [],
    onKeyDown: function onKeyDown(event) {
      if (event.keyCode === Kobo.KeyCodes.ESC) {
        if (!existsModal() || !isOpen()) {
          return;
        }

        hideModal();
      }

      if (isOpen && event.which === Kobo.KeyCodes.TAB) {
        accessibility.trapTabKey(event);
      }
    },
    trapTabKey: function trapTabKey(event) {
      var focusable = accessibility.getFocusableElements();
      var elementIndex = focusable.indexOf(document.activeElement); // If the SHIFT key is being pressed while tabbing (moving backwards) and
      // the currently focused item is the first one, move the focus to the last
      // focusable item from the dialog element

      if (event.shiftKey && elementIndex === 0) {
        focusable[focusable.length - 1].focus();
        event.preventDefault();
      } // If the SHIFT key is not being pressed (moving forwards) and the currently
      // focused item is the last one, move the focus to the first focusable item
      // from the dialog element
      else if (!event.shiftKey && (elementIndex === focusable.length - 1 || elementIndex === -1)) {
        focusable[0].focus();
        event.preventDefault();
      }
    },
    elementVisibleFilter: function elementVisibleFilter() {
      var $this = Kobo.$(this);
      return $this.is(':visible') && $this.css('opacity') > 0 && !$this.is('[aria-hidden="true"]');
    },
    focusable: function focusable(element, hasTabIndex) {
      var nodeName = element.nodeName.toLowerCase();

      if (/^(input|select|textarea|button|object)$/.test(nodeName)) {
        return !element.disabled;
      } else if ("a" === nodeName) {
        return element.href || hasTabIndex;
      }

      return hasTabIndex;
    },
    tabbable: function tabbable(element) {
      var tabIndex = Kobo.$.attr(element, "tabindex"),
          isTabIndexNaN = isNaN(tabIndex);
      return (isTabIndexNaN || tabIndex >= 0) && accessibility.focusable(element, !isTabIndexNaN) && !!element.offsetParent;
    },
    focusFirstElement: function focusFirstElement() {
      var focusableElements = accessibility.getFocusableElements();

      if (focusableElements.length) {
        focusableElements[0].focus();
        return false;
      }
    },
    getFocusableElements: function getFocusableElements() {
      return Array.prototype.slice.call($modalContentDiv.find('*').filter(accessibility.elementVisibleFilter)).filter(function (element) {
        return accessibility.tabbable(element);
      });
    },
    focusPreviousActiveElement: function focusPreviousActiveElement() {
      if ($previousActiveElement && $previousActiveElement.length) {
        $previousActiveElement.trigger("focus");
      }
    },

    /**
     * We want to hide any other elements on the page so that screen readers will ignore them
     */
    hideNonModalContent: function hideNonModalContent() {
      if (!accessibility.$hiddenSiblings.length) {
        accessibility.$hiddenSiblings = $modalDiv.siblings().filter(accessibility.elementVisibleFilter);
      }

      $modalDiv.attr('aria-hidden', false);
      accessibility.$hiddenSiblings.attr('aria-hidden', 'true');
    },
    showNonModalContent: function showNonModalContent() {
      if (accessibility.$hiddenSiblings.length) {
        accessibility.$hiddenSiblings.attr('aria-hidden', false);
      }

      !!$modalDiv && $modalDiv.attr('aria-hidden', true);
    },
    init: function init() {
      // Store the currently focused element so we can restore focus to it when the modal closes
      Kobo.$document.on('keydown', accessibility.onKeyDown); // Using native addEventListener to focus first element after modal content is loaded

      $modalContentDiv[0].addEventListener('load', function () {
        if ($modalContentDiv.find('*').has(document.activeElement).length === 0) {
          accessibility.focusFirstElement();
        }
      }, true);
      accessibility.focusFirstElement();
      accessibility.hideNonModalContent();
    },
    destroy: function destroy() {
      Kobo.$document.off('keydown', accessibility.onKeyDown);
      accessibility.showNonModalContent();
      accessibility.focusPreviousActiveElement();
      $previousActiveElement = null;
      accessibility.$hiddenSiblings = [];
    }
  };

  constrainContent = function constrainContent() {
    if ($modalContentDiv.outerHeight(true) > Kobo.$window.height()) {
      $modalContentDiv.css({
        verticalAlign: 'top'
      });
    } else {
      $modalContentDiv.css({
        verticalAlign: ''
      });
    } // Set the width to auto so that any overflow
    // content will take up it's uncontrained width


    $modalContentDiv.css('width', 'auto');
    var modalContentWidth = $modalContentDiv.outerWidth(true); // If the unconstrained width is less than the window it means
    // there is no overflow, so we can reset back to the default css
    // otherwise we just leave as auto and allow the overflow css to
    // handle adding scrollbars
    // Add 1 to avoid issues at breakpoint boundaries 

    if (modalContentWidth + 1 <= Kobo.$window.width()) {
      $modalContentDiv.css('width', '');
    }
  };

  onWindowResize = Kobo.Utilities.Events.debounce(constrainContent);

  hideModal = function hideModal() {
    Kobo.$body.removeClass(SHOW_CLASS).css('overflow', '');
    Kobo.$window.off('resize', onWindowResize);
    accessibility.destroy();
  };

  showModal = function showModal() {
    Kobo.$window.on('resize', onWindowResize);
    constrainContent();
    Kobo.$body.addClass(SHOW_CLASS).css('overflow', 'hidden');
    accessibility.init();
  };

  isOpen = function isOpen() {
    return Kobo.$body.hasClass(SHOW_CLASS);
  };

  stopPropagation = function stopPropagation(event) {
    event.stopPropagation();
  };

  parseOptions = function parseOptions(options, modalDiv, modalContentDiv) {
    var _modalDiv = modalDiv || $modalDiv,
        _modalContentDiv = modalContentDiv || $modalContentDiv; // Remove any previously set themes and/or custom classes


    _modalDiv.removeClass();

    _modalContentDiv.removeClass();

    if (options.isCancellable) {
      _modalContentDiv.on('click', stopPropagation);

      _modalDiv.on('click', Kobo._modal.close);
    } else {
      _modalContentDiv.off('click', stopPropagation);

      _modalDiv.off('click', Kobo._modal.close);
    }

    if (options.theme) {
      _modalDiv.addClass(options.theme);
    }

    if (options.customClass) {
      _modalContentDiv.addClass(options.customClass);
    }

    if (options.role) {
      _modalContentDiv.attr('role', options.role);
    }

    if (options.ariaLabelledBy) {
      _modalContentDiv.attr('aria-labelledby', options.ariaLabelledBy);
    }

    if (!options.ariaLabelledBy && options.ariaLabel) {
      _modalContentDiv.attr('aria-label', options.ariaLabel);
    }
  };

  return {
    /**
     * Displays a modal window over the browser viewport
     * If the modal is already open, the existing contents will be replaced
     *
     * Styling and dimensions of the content is done purely in CSS.
     * A custom class name can be passed in,  which will be added to the
     * modal box's class list.
     *
     * By default, the modal window is centred horizontally and vertically,
     * and has a width of 50% of the viewport. These settings can be overwritten.
     * For example,
     * #modal-content.my-custom-class {
     *   width: 800px; // have a fixed width modal box
     * }
     *
     * Options can be passed in to control the behaviour of the modal box.
     * options.customClass: A class to apply on the modal-content element
     * options.theme: Themes to apply to the root modal element.
     * options.isCancellable: Whether the modal box should be closed when a click is received outside of it. Default value: false (not implemented)
     * options.role: The aria role to apply to this modal dialog
     * options.ariaLabelledBy: Element id to be link as accessibility name to modal container 
     * options.ariaLabel: Text will be used, only if ariaLabelledBy is not provided, to announce accessibility name for modal container.  
     *
     * @param contents The root HTML element of the contents to be displayed
     * @param options Optional. An object containing various properties as described above
     */
    // For unit tests
    SHOW_CLASS: SHOW_CLASS,

    /**
     * Styles for the root modal element
     * By default, the modal will appear with a translucent black overlay applied to the rest of the viewport
     */
    themes: {
      LIGHT: 'light',

      /* A translucent white overlay over the viewport */
      TRANSPARENT: 'transparent',

      /* A transparent overlay */
      LIGHT_OPAQUE: 'light-opaque',

      /* An opaque white overlay */
      INSTANT_PREVIEW: 'instant-preview',

      /* Instant Preview needs special styling */
      PITCH_BLACK: 'pitch-black',

      /* A pitch black overlay */
      TRANSLUCENT_BLACK_42: 'translucent-black-42'
      /* A translucent black overlay with 42% transparency */

    },

    /**
     * Roles available to be set, the correct role should be used based on the modal contents
     * By default, the "dialog" role will be used
     */
    roles: {
      INFO: 'dialog',

      /* A general modal that has been displayed */
      ERROR: 'alertdialog'
      /* A modal that needs immediate attention */

    },

    /**
     * Allows external scripts to query if there is a modal currently open
     */
    isOpen: isOpen,
    open: function open(contents, options) {
      createModal();
      options = options || {};
      parseOptions(options);

      if (contents) {
        $previousActiveElement = Kobo.$(document.activeElement);
        $modalContentDiv.html(contents);
        showModal();
      }
    },
    openWithExistModal: function openWithExistModal(contents, options) {
      createModalWithExistModal(options.extraModalAppendTarget);
      options = options || {};
      parseOptions(options, $modalDiv_extra, $modalContentDiv_extra);

      if (contents) {
        $previousActiveElement = Kobo.$(document.activeElement);
        $modalContentDiv_extra.html(contents);
        showModal();
      }
    },

    /**
     * Hides the modal. This does not remove the contents in it.
     * Does nothing if the modal is already hidden
     */
    close: function close() {
      if (!existsModal() || !isOpen()) {
        return;
      }

      hideModal();
    },

    /**
     * Removes the modal element from the DOM and resets the private variables.
     */
    destroy: function destroy() {
      // Remove the body class
      hideModal(); // Remove from DOM

      if ($modalDiv) {
        $modalDiv.remove();
      }

      Kobo.$window.off('resize', onWindowResize);
      accessibility.destroy(); // Reset private variables

      $modalDiv = null;
      $modalContentDiv = null;
    }
  };
}();