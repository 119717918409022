"use strict";

ko.bindingHandlers.blurWrapper = ko.bindingHandlers.blurWrapper || {
  init: function init(element, valueAccessor) {
    var $element = Kobo.$(element);

    var keyUpHandler = function keyUpHandler(event) {
      if (event.keyCode === Kobo.KeyCodes.TAB) {
        if (Kobo.$(event.target).closest($element).length === 0) {
          var action = valueAccessor();
          action(event);
        }
      }
    };

    Kobo.$('html').on('keyup', keyUpHandler);
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      Kobo.$('html').off('keyup', keyUpHandler);
    });
  }
};