"use strict";

/*global Kobo,$,ko,Modernizr*/
if (!Kobo.Mixin) {
  Kobo.Mixin = {};
}

Kobo.Mixin.HorizontalScrollAnimation = function (element, options) {
  "use strict";

  this.listElement = element;
  this.$container = Kobo.$(this.listElement).parent();
  this.options = options || {};
  this.duration = this.options.duration || 200;
  this.iterationDuration = this.options.iterationDuration || 10;
  this.iterations = parseInt(this.options.iterations || this.duration / this.iterationDuration, 10);
  this.timeoutState = {
    timeoutId: null
  };
  this.callback = this.options.callback;
  this.isError = false;
  this.left = {
    value: 0
  };
};

Kobo.Mixin.HorizontalScrollAnimation.prototype.animate = function (params, callback) {
  "use strict";

  if (!params || typeof params.scrollPositionIndex === 'undefined' || typeof params.itemFullWidth === 'undefined') {
    Kobo._error.error('Mixin.params', 'Kobo.Mixin.HorizontalScrollAnimation', this, {
      msg: 'Mandatory parameter missing {scrollPositionIndex, itemFullWidth}',
      params: params
    });

    this.isError = true;
    return;
  }

  this.left.value = parseInt(this.$container[0].scrollLeft, 10);
  this.itemWidth = params.itemFullWidth;
  this.scrollPositionIndex = params.scrollPositionIndex;
  var self = this,
      scrollPositionIndex = params.scrollPositionIndex,
      // "-1" is a correction because of the floating margin value:
  newLeft = scrollPositionIndex * this.itemWidth - 1,
      scrollWidth = newLeft - this.left.value;
  this.left.dx = scrollWidth / this.iterations;

  callback = this.callback || callback || function () {};

  if (params.noAnimation) {
    this.$container.scrollLeft(newLeft);
    return;
  }

  function move() {
    self.left.value = self.left.value + self.left.dx;
    self.$container.scrollLeft(self.left.value);
  }

  this.timeoutState.fn = move;
  this.timeoutState.times = this.iterations;
  this.timeoutState.timeout = this.iterationDuration;
  this.timeoutState.callback = callback;
  Kobo.scheduleAction(this.timeoutState);
};

Kobo.Mixin.HorizontalScrollAnimation.prototype.pause = function () {
  "use strict";

  clearTimeout(this.timeoutState.timeoutId);
};

Kobo.Mixin.HorizontalScrollAnimation.prototype.resume = function (scrollPositionIndex) {
  "use strict";

  this.left.value = this.$container.scrollLeft();

  if (typeof scrollPositionIndex === 'undefined') {
    scrollPositionIndex = this.scrollPositionIndex;
  }

  var finalLeft = scrollPositionIndex * this.itemWidth,
      times = parseInt((finalLeft - this.left.value) / this.left.dx, 10);
  this.timeoutState.times = times;
  Kobo.scheduleAction(this.timeoutState);
};

Kobo.Mixin.VerticalScrollAnimation = function (listElement, options) {
  "use strict";

  this.options = options || {};
  this.listElement = listElement;
  this.$listElement = Kobo.$(listElement);
};

Kobo.Mixin.VerticalScrollAnimation.prototype.animate = function (params) {
  "use strict";

  var scrollPositionIndex = params.scrollPositionIndex,
      itemHeight = params.itemFullHeight,
      iterations = this.options.iterations || 20,
      top = parseInt(this.$listElement.css('top'), 10) || 0,
      // in case its top:'auto'
  newTop = params.newTop || -(scrollPositionIndex * itemHeight),
      scrollHeight = newTop - top,
      dy = scrollHeight / iterations,
      $listElement = this.$listElement;

  if (params.noAnimation) {
    $listElement.css('top', newTop);
    return;
  }

  function move() {
    top = top + dy;
    $listElement.css('top', Math.round(top));
  }

  Kobo.scheduleAction({
    fn: move,
    times: iterations,
    timeout: 10
  });
};

Kobo.Mixin.TouchSupport = function () {
  "use strict";

  var _gizmo,
      _$gizmo,
      _viewModel,
      self,
      touchStart,
      touchMove,
      touchEnd,
      _numberOfBooksTillEndReached,
      _widthOfEachBook,
      _containerWidth,
      _callback,
      _scrollPositionIndex,
      _itemFullWidth,
      _offset,
      _startIndex,
      _endIndex,
      _scrollStartPos,
      _direction,
      isManualScrollingEnabled = true;

  touchStart = function touchStart(e) {
    self.proxyTouchEventToGizmo(e);
  };

  touchMove = function touchMove(e) {};

  touchEnd = function touchEnd(e) {// scroll by that much
  };

  return {
    isGizmoScrollable: function isGizmoScrollable() {
      if (_gizmo.isScrollable) {
        return _gizmo.isScrollable();
      } else {
        // fall back where scrollable property not set on gizmo
        // case 1: we try to find a ul-container
        // case 2: if no ul-container then find ul and see
        var $gizmo = _gizmo.$gizmo,
            ul = $gizmo.find('ul'),
            scrollingList = _gizmo.elementList,
            ulParent = ul.parent(),
            ulParentOverflowValue = ulParent.css('overflow'),
            ulContainer = $gizmo.find('.ul-container'),
            ulContainerOverflowProperty = ulContainer.css('overflow'),
            scrollable;

        if (scrollingList) {
          // this means we got a scrolling list
          scrollable = Kobo.$(scrollingList).parent().css('overflow');
          return scrollable === "auto" || scrollable === "scroll";
        } else if (ulContainer) {
          scrollable = $gizmo.find('.ul-container').css('overflow');
          return scrollable === "scroll" || scrollable === "auto";
        } else if (ul && ul.width() > ul.parent().width() && ul.parent().css('overflow') === "auto") {
          return true;
        }

        return false;
      }
    },
    areCaretsVisible: function areCaretsVisible() {
      if (_gizmo.caretsVisibleWhenTouch) {
        return _gizmo.caretsVisibleWhenTouch;
      } else {
        // we assume that there is a navigation
        if (_$gizmo.find('.nav button').first().hasClass('disabled')) {
          return false;
        } else {
          return true;
        }
      }
    },
    setupTouchEvents: function setupTouchEvents() {
      var self = this,
          touchEventsSetup = false;

      if (this.isTouchSupported()) {
        if (this.doTouchCallbacksExistInGizmo()) {
          // register the callbacks
          _$gizmo.on('touchstart', _gizmo.settings.touchStart);

          _$gizmo.on('touchmove', _gizmo.settings.touchMove);

          _$gizmo.on('touchend', _gizmo.settings.touchEnd);

          touchEventsSetup = true;
        } else {
          // default callbacks
          _$gizmo.on('touchstart', function (event) {
            _scrollStartPos = this.scrollTop + event.originalEvent.touches[0].pageX;
          });

          _$gizmo.on('touchmove', function (event) {
            var diff = _scrollStartPos - event.originalEvent.touches[0].pageX;
            self.setScrollPosition(_scrollStartPos, diff);
            event.preventDefault();
          });

          _$gizmo.on('touchend', function (event) {});

          touchEventsSetup = true;
        }
      }

      return touchEventsSetup;
    },
    setScrollPosition: function setScrollPosition(orignalPosition, difference) {
      var sLeft = _$gizmo.find('.ul-container').scrollLeft();

      if (difference === 0 || difference !== orignalPosition) {
        _$gizmo.find('.ul-container').scrollLeft(sLeft); // keep the original

      } else {
        _$gizmo.find('.ul-container').scrollLeft(difference); // keep the original

      }

      if (difference > 0) {
        _direction = "right";
      } else {
        _direction = "left";
      }
    },
    doTouchCallbacksExistInGizmo: function doTouchCallbacksExistInGizmo() {
      return typeof _gizmo.settings.touchStart === 'function' && typeof _gizmo.settings.touchMove === 'function' && typeof _gizmo.settings.touchEnd === 'function';
    },
    disableScrollingIfCaretsShown: function disableScrollingIfCaretsShown() {
      isManualScrollingEnabled = false;
      return isManualScrollingEnabled;
    },
    proxyTouchEventToGizmo: function proxyTouchEventToGizmo(e) {
      if (_gizmo.$el && _gizmo.$el.find('.ul-container')) {
        _gizmo.$el.find('.ul-container').trigger('click', e);

        return true;
      }

      return false; // failed proxy call
    },
    isTouchSupported: function isTouchSupported() {
      return Modernizr.touchevents;
    },
    isTouchSupportedForScreenSize: ko.computed(function () {
      var screenSize = Kobo._mediator.screenSizeObservable();

      return Modernizr.touchevents && (screenSize === 'small' || screenSize === 'medium');
    }),
    doesGizmoContainItems: function doesGizmoContainItems() {
      if (_gizmo.viewModels && _gizmo.viewModels.listViewModel && _gizmo.viewModels.listViewModel.items().length > 0) {
        return true;
      }

      return false;
    },
    isEndOfItemsReached: function isEndOfItemsReached(scrollLeft, widthOfContainer, offset) {
      var _widthOfContainer = widthOfContainer - _$gizmo.find('.ul-container').width();

      if (scrollLeft >= _widthOfContainer - offset && scrollLeft <= _widthOfContainer) {
        return true;
      } else if (scrollLeft > 0 && scrollLeft < offset) {
        return true;
      } else {
        return false;
      }
    },
    subscribeToEndOfItems: function subscribeToEndOfItems(gizmo, callback) {
      if (gizmo && gizmo.$gizmo && typeof callback === 'function') {
        gizmo.$gizmo.on('endOfItems', callback);
        return true;
      }

      return false;
    },
    subscribeToScroll: function subscribeToScroll() {
      var self = this;

      if (_$gizmo.find('.ul-container')) {
        _$gizmo.find('.ul-container').on('scroll', this.updateScrollPositionIndex);

        return true;
      }

      return false;
    },
    fireEndOfItemsEventIfNeeded: function fireEndOfItemsEventIfNeeded() {
      var scrollLeft,
          widthOfBook,
          widthOfContainer,
          direction,
          eventFired = false;
      scrollLeft = _$gizmo.find('.ul-container').scrollLeft();
      widthOfContainer = _$gizmo.find('.ul-container ul').width();

      if (self.isEndOfItemsReached(scrollLeft, widthOfContainer, _offset)) {
        _$gizmo.trigger('endOfItems');

        eventFired = true;
      }

      return eventFired;
    },
    updateScrollPositionIndex: function updateScrollPositionIndex(e) {
      var scrollLeft = _$gizmo.find('.ul-container').scrollLeft(),
          scrollPosition = self.getNewPositionIndex(scrollLeft, _itemFullWidth, _direction);

      _scrollPositionIndex(scrollPosition);
    },
    getNewPositionIndex: function getNewPositionIndex(scrollLeft, itemFullWidth, scrollDirection) {
      var floatScrollPositionIndex = scrollLeft / itemFullWidth,
          newScrollPositionIndex;

      if (scrollDirection === 'left') {
        newScrollPositionIndex = parseInt(floatScrollPositionIndex, 10);
      } else {
        newScrollPositionIndex = Math.ceil(floatScrollPositionIndex);
      }

      return newScrollPositionIndex;
    },
    initialize: function initialize(gizmo, options) {
      var successfullyInitialized = false;
      _gizmo = gizmo;
      _scrollPositionIndex = options.scrollPositionIndex;
      _itemFullWidth = options.itemFullWidth;
      _$gizmo = gizmo.$gizmo;
      _offset = options ? options.offset || 25 : 25;
      self = this;
      _numberOfBooksTillEndReached = options && options.booksTillEndReached || 2;
      _widthOfEachBook = options && options.widthOfBook || 100;
      _containerWidth = options && options.containerWidth || _$gizmo.find('.ul-container').width();
      return successfullyInitialized;
    }
  };
};