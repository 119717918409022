"use strict";

/*globals Kobo */
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.PurchasePath.Enums', {
    Stage: {
      Unknown: 'unknown',
      Payment: 'payment',
      Review: 'review'
    },
    PaymentType: {
      Unknown: 'unknown',
      CreditCard: 'credit-card',
      VisaCheckout: 'vme',
      OgoneMistercash: 'ogoneMistercash',
      OgoneElv: 'ogoneElv',
      OgoneSofort: 'ogoneSofort',
      RgiSuperPoints: 'rgiSuperPoints',
      Orange: 'orange',
      BraintreeCreditCard: 'braintree',
      BraintreePaypal: 'braintreePaypal',
      BraintreeVisaSRC: 'braintreeVisaSRC',
      SOFORT: 'sofort',
      BraintreeSofort: 'braintreeSofort',
      BraintreeBancontact: 'braintreeBancontact',
      BraintreeApplePay: 'BraintreeApplePay',
      BraintreeGooglePay: 'BraintreeGooglePay',
      SepaStripe: 'sepaStripe'
    },
    BraintreeLocalPaymentType: {
      Bancontact: 'bancontact',
      SOFORT: 'sofort'
    },
    GiftCardType: {
      Kobo: 'kobo',
      Indigo: 'indigo',
      WHSmith: 'whsmith'
    },
    LineItemType: {
      Unknown: 'unknown',
      Cost: 'cost',
      Discount: 'discount'
    },
    CreditCardType: {
      Unknown: 'unknown',
      MasterCard: 'mastercard',
      Visa: 'visa',
      AmericanExpress: 'amex',
      Discover: 'discover',
      JCB: 'jcb',
      DinersClub: 'diners',
      Braintree: 'braintree',
      AmericanExpressBraintree: 'american express'
    },
    PurchaseStatus: {
      Busy: 'busy',
      Complete: 'complete',
      Failed: 'failed',
      Cancelled: 'cancelled',
      Idle: 'idle',
      Redirecting: 'redirecting'
    },
    PurchaseStatusHint: {
      Unknown: 'unknown',
      PayPal: 'paypal',
      SepaStripe: 'sepaStripe',
      AdvanceToCheckout: 'advanceToCheckout'
    },
    CheckoutActionType: {
      Redirect: 'redirect',
      ShowErrorMessage: 'show-error-message',
      DeselectAccordionNavigationCheckbox: 'deselectAccordionNavigationCheckbox',
      ResetAvailableCreditCardForBT: 'resetAvailableCreditCardForBT',
      TriggerLocalPayment: 'triggerLocalPayment'
    },
    MessageBoxType: {
      Warning: 'warning',
      Error: 'error',
      Success: 'success'
    },
    PaymentDiscountType: {
      Unknown: 'unknown',
      PromoCode: 'promo-code',
      StoreCredit: 'store-credit',
      GiftCard: 'gift-card'
    },
    Fields: {
      Country: 'Country'
    },
    ThreeDSecure: {
      Name: "ThreeDSecure",
      ChallengeStatus: {
        NotTriggered: 'notTriggered',
        ChallengeStarted: 'challengeStarted',
        ChallengeCanceled: 'challengeCanceled',
        ChallengeSuccess: 'challengeSuccess',
        ChallengeFailure: 'challengeFailure',
        NoChallengeSuccess: 'noChallengeSuccess',
        NoChallengeFailure: 'noChallengeFailure'
      },
      VerifyCaller: {
        PurchasePath: 'purchasePath',
        AccountPaymentMethod: 'accountPaymentMethod'
      },
      VerifyAction: {
        Purchase: 'purchase',
        Vault: 'vault'
      }
    },
    BraintreeError: {
      Name: 'BraintreeError',
      Code: {
        ThreeDSecureNotEnabledForV2: 'THREEDS_NOT_ENABLED_FOR_V2'
      }
    },
    AlternativePaymentMethod: {
      Name: "AlternativePaymentMethod",
      Status: {
        Started: 'started',
        Success: 'success',
        Failure: 'failure',
        Canceled: 'canceled'
      }
    },
    BraintreeHostedFields: {
      CreditCard: {
        Number: 'number',
        Cvv: 'cvv',
        ExpirationMonth: 'expirationMonth',
        ExpirationYear: 'expirationYear'
      }
    },
    PaymentMethodType: {
      BraintreeCreditCard: 'BraintreeCreditCard',
      BraintreePayPal: 'BraintreePaypal',
      SepaStripe: 'SepaStripe'
    },
    ContinuePurchaseOnWebPollingStatus: {
      Pending: 'Pending',
      HasAccessedWebPurchasePath: 'HasAccessedWebPurchasePath',
      Error: 'Error',
      TokenExpired: 'TokenExpired',
      PaymentHasStarted: 'PaymentHasStarted'
    },
    PurchaseOnWebTokenValidationResultStatus: {
      Redirect: 'Redirect',
      Failure: 'Failure',
      TokenDoesNotBelongToUser: 'TokenDoesNotBelongToUser'
    },
    PayPalCheckoutFailureReason: {
      ClientSetupError: 'ClientSetupError',
      AuthorizeError: 'AuthorizeError',
      TokenizeError: 'TokenizeError'
    },
    BraintreeFieldsElementIdMapper: {
      'number': '#error-message-ccnum',
      'cvv': '#error-message-cccvv',
      'expirationMonth': '#expiryDateInvalidMsg',
      'expirationYear': '#expiryDateInvalidMsg'
    },
    ParsePaymentTypeFromServer: function ParsePaymentTypeFromServer(type) {
      switch (type) {
        case 0:
          return Kobo.PurchasePath.Enums.PaymentType.Unknown;

        case 1:
          return Kobo.PurchasePath.Enums.PaymentType.CreditCard;

        case 3:
          return Kobo.PurchasePath.Enums.PaymentType.VisaCheckout;

        case 5:
          return Kobo.PurchasePath.Enums.PaymentType.GiftCard;

        case 6:
          return Kobo.PurchasePath.Enums.PaymentType.OgoneMistercash;

        case 8:
          return Kobo.PurchasePath.Enums.PaymentType.OgoneElv;

        case 9:
          return Kobo.PurchasePath.Enums.PaymentType.OgoneSofort;

        case 10:
          return Kobo.PurchasePath.Enums.PaymentType.RgiSuperPoints;

        case 11:
          return Kobo.PurchasePath.Enums.PaymentType.Orange;

        case 12:
          return Kobo.PurchasePath.Enums.PaymentType.BraintreeCreditCard;

        case 13:
          return Kobo.PurchasePath.Enums.PaymentType.BraintreePaypal;

        case 14:
          return Kobo.PurchasePath.Enums.PaymentType.BraintreeVisaSRC;

        case 15:
          return Kobo.PurchasePath.Enums.PaymentType.BraintreeSofort;

        case 17:
          return Kobo.PurchasePath.Enums.PaymentType.BraintreeBancontact;

        case 18:
          return Kobo.PurchasePath.Enums.PaymentType.BraintreeApplePay;

        case 19:
          return Kobo.PurchasePath.Enums.PaymentType.BraintreeGooglePay;

        case 21:
          return Kobo.PurchasePath.Enums.PaymentType.SepaStripe;

        default:
          throw new Error("Unsupported payment option type: " + type);
      }
    },
    ConvertPaymentTypeToServer: function ConvertPaymentTypeToServer(type) {
      switch (type) {
        case Kobo.PurchasePath.Enums.PaymentType.Unknown:
          return 0;

        case Kobo.PurchasePath.Enums.PaymentType.CreditCard:
          return 1;

        case Kobo.PurchasePath.Enums.PaymentType.VisaCheckout:
          return 3;

        case Kobo.PurchasePath.Enums.PaymentType.GiftCard:
          return 5;

        case Kobo.PurchasePath.Enums.PaymentType.OgoneMistercash:
          return 6;

        case Kobo.PurchasePath.Enums.PaymentType.OgoneElv:
          return 8;

        case Kobo.PurchasePath.Enums.PaymentType.OgoneSofort:
          return 9;

        case Kobo.PurchasePath.Enums.PaymentType.RgiSuperPoints:
          return 10;

        case Kobo.PurchasePath.Enums.PaymentType.Orange:
          return 11;

        case Kobo.PurchasePath.Enums.PaymentType.BraintreeCreditCard:
          return 12;

        case Kobo.PurchasePath.Enums.PaymentType.BraintreePaypal:
          return 13;

        case Kobo.PurchasePath.Enums.PaymentType.BraintreeVisaSRC:
          return 14;

        case Kobo.PurchasePath.Enums.PaymentType.BraintreeSofort:
          return 15;

        case Kobo.PurchasePath.Enums.PaymentType.BraintreeBancontact:
          return 17;

        case Kobo.PurchasePath.Enums.PaymentType.BraintreeApplePay:
          return 18;

        case Kobo.PurchasePath.Enums.PaymentType.BraintreeGooglePay:
          return 19;

        case Kobo.PurchasePath.Enums.PaymentType.SepaStripe:
          return 21;

        default:
          throw new Error("Unsupported payment option type: " + type);
      }
    },
    ConvertCreditCardTypeToPaymentType: function ConvertCreditCardTypeToPaymentType(paymentType) {
      switch (paymentType) {
        case Kobo.PurchasePath.Enums.CreditCardType.MasterCard:
        case Kobo.PurchasePath.Enums.CreditCardType.Visa:
        case Kobo.PurchasePath.Enums.CreditCardType.AmericanExpress:
        case Kobo.PurchasePath.Enums.CreditCardType.Discover:
        case Kobo.PurchasePath.Enums.CreditCardType.JCB:
        case Kobo.PurchasePath.Enums.CreditCardType.DinersClub:
        case Kobo.PurchasePath.Enums.CreditCardType.Braintree:
          return Kobo.PurchasePath.Enums.PaymentType.CreditCard;

        default:
          return paymentType;
      }
    },
    ParseLineItemTypeFromServer: function ParseLineItemTypeFromServer(type) {
      switch (type) {
        case 0:
          return Kobo.PurchasePath.Enums.LineItemType.Unknown;

        case 1:
          return Kobo.PurchasePath.Enums.LineItemType.Cost;

        case 2:
          return Kobo.PurchasePath.Enums.LineItemType.Discount;

        default:
          throw new Error('Unsupported line item type: ' + type);
      }
    },
    ConvertLineItemTypeToServer: function ConvertLineItemTypeToServer(type) {
      switch (type) {
        case Kobo.PurchasePath.Enums.LineItemType.Unknown:
          return 0;

        case Kobo.PurchasePath.Enums.LineItemType.Cost:
          return 1;

        case Kobo.PurchasePath.Enums.LineItemType.Discount:
          return 2;

        default:
          throw new Error('Unsupported line item type: ' + type);
      }
    },
    ParseCardTypeFromServer: function ParseCardTypeFromServer(type) {
      switch (type) {
        case 1:
          return Kobo.PurchasePath.Enums.CreditCardType.Visa;

        case 2:
          return Kobo.PurchasePath.Enums.CreditCardType.MasterCard;

        case 3:
          return Kobo.PurchasePath.Enums.CreditCardType.Discover;

        case 4:
          return Kobo.PurchasePath.Enums.CreditCardType.AmericanExpress;

        case 5:
          return Kobo.PurchasePath.Enums.CreditCardType.JCB;

        case 6:
          return Kobo.PurchasePath.Enums.CreditCardType.DinersClub;

        case 8:
          return Kobo.PurchasePath.Enums.CreditCardType.Braintree;

        default:
          return Kobo.PurchasePath.Enums.CreditCardType.Unknown;
      }
    },
    ConvertCardTypeToServer: function ConvertCardTypeToServer(type) {
      switch (type) {
        case Kobo.PurchasePath.Enums.CreditCardType.Visa:
          return 1;

        case Kobo.PurchasePath.Enums.CreditCardType.MasterCard:
          return 2;

        case Kobo.PurchasePath.Enums.CreditCardType.Discover:
          return 3;

        case Kobo.PurchasePath.Enums.CreditCardType.AmericanExpress:
        case Kobo.PurchasePath.Enums.CreditCardType.AmericanExpressBraintree:
          return 4;

        case Kobo.PurchasePath.Enums.CreditCardType.JCB:
          return 5;

        case Kobo.PurchasePath.Enums.CreditCardType.DinersClub:
          return 6;

        case Kobo.PurchasePath.Enums.CreditCardType.Braintree:
          return 8;

        default:
          return 0;
        // unknown
      }
    },
    ParseGiftCardTypeFromServer: function ParseGiftCardTypeFromServer(type) {
      switch (type) {
        case 0:
          return Kobo.PurchasePath.Enums.GiftCardType.Kobo;

        case 1:
          return Kobo.PurchasePath.Enums.GiftCardType.Indigo;

        case 2:
          return Kobo.PurchasePath.Enums.GiftCardType.WHSmith;

        default:
          throw new Error('Unsupported gift card type: ' + type);
      }
    },
    ConvertGiftCardTypeToServer: function ConvertGiftCardTypeToServer(type) {
      switch (type) {
        case Kobo.PurchasePath.Enums.GiftCardType.Kobo:
          return 0;

        case Kobo.PurchasePath.Enums.GiftCardType.Indigo:
          return 1;

        case Kobo.PurchasePath.Enums.GiftCardType.WHSmith:
          return 2;

        default:
          throw new Error('Unsupported gift card type: ' + type);
      }
    },
    ParsePurchaseStatusFromServer: function ParsePurchaseStatusFromServer(status) {
      switch (status) {
        case 1:
          return Kobo.PurchasePath.Enums.PurchaseStatus.Busy;

        case 2:
          return Kobo.PurchasePath.Enums.PurchaseStatus.Complete;

        case 3:
          return Kobo.PurchasePath.Enums.PurchaseStatus.Failed;

        case 4:
          return Kobo.PurchasePath.Enums.PurchaseStatus.Cancelled;

        default:
          throw new Error("Unsupported purchase status: " + status);
      }
    },
    ConvertPurchaseStatusToServer: function ConvertPurchaseStatusToServer(status) {
      switch (status) {
        case Kobo.PurchasePath.Enums.PurchaseStatus.Busy:
          return 1;

        case Kobo.PurchasePath.Enums.PurchaseStatus.Complete:
          return 2;

        case Kobo.PurchasePath.Enums.PurchaseStatus.Failed:
          return 3;

        case Kobo.PurchasePath.Enums.PurchaseStatus.Cancelled:
          return 4;

        default:
          throw new Error("Unsupported purchase status: " + status);
      }
    },
    ParseCheckoutActionTypeFromServer: function ParseCheckoutActionTypeFromServer(type) {
      switch (type) {
        case 1:
          return Kobo.PurchasePath.Enums.CheckoutActionType.Redirect;

        case 2:
          return Kobo.PurchasePath.Enums.CheckoutActionType.ShowErrorMessage;

        case 3:
          return Kobo.PurchasePath.Enums.CheckoutActionType.DeselectAccordionNavigationCheckbox;

        case 4:
          return Kobo.PurchasePath.Enums.CheckoutActionType.ResetAvailableCreditCardForBT;

        case 5:
          return Kobo.PurchasePath.Enums.CheckoutActionType.TriggerLocalPayment;

        default:
          throw new Error("Unsupported checkout action type: " + type);
      }
    },
    ConvertCheckoutActionTypeToServer: function ConvertCheckoutActionTypeToServer(status) {
      switch (status) {
        case Kobo.PurchasePath.Enums.CheckoutActionType.Redirect:
          return 1;

        case Kobo.PurchasePath.Enums.CheckoutActionType.ShowErrorMessage:
          return 2;

        case Kobo.PurchasePath.Enums.CheckoutActionType.DeselectAccordionNavigationCheckbox:
          return 3;

        case Kobo.PurchasePath.Enums.CheckoutActionType.ResetAvailableCreditCardForBT:
          return 4;

        case Kobo.PurchasePath.Enums.CheckoutActionType.TriggerLocalPayment:
          return 5;

        default:
          throw new Error("Unsupported checkout action type: " + type);
      }
    },
    ParsePaymentDiscountTypeFromServer: function ParsePaymentDiscountTypeFromServer(type) {
      switch (type) {
        case 1:
          return Kobo.PurchasePath.Enums.PaymentDiscountType.PromoCode;

        case 2:
          return Kobo.PurchasePath.Enums.PaymentDiscountType.StoreCredit;

        case 3:
          return Kobo.PurchasePath.Enums.PaymentDiscountType.GiftCard;

        default:
          return Kobo.PurchasePath.Enums.PaymentDiscountType.Unknown;
      }
    },
    ParsePurchaseStatusHintFromServer: function ParsePurchaseStatusHintFromServer(statusHint) {
      switch (statusHint) {
        case 0:
          return Kobo.PurchasePath.Enums.PurchaseStatusHint.PayPal;

        case 31:
          return Kobo.PurchasePath.Enums.PurchaseStatusHint.SepaStripe;

        default:
          return Kobo.PurchasePath.Enums.PurchaseStatusHint.Unknown;
      }
    },
    ParseMessageBoxTypeFromServer: function ParseMessageBoxTypeFromServer(type) {
      switch (type) {
        case 1:
          return Kobo.PurchasePath.Enums.MessageBoxType.Warning;

        case 2:
          return Kobo.PurchasePath.Enums.MessageBoxType.Error;

        case 3:
          return Kobo.PurchasePath.Enums.MessageBoxType.Success;

        default:
          throw new Error("Unsupported message box type: " + type);
      }
    },
    ConvertMessageBoxTypeToServer: function ConvertMessageBoxTypeToServer(type) {
      switch (type) {
        case Kobo.PurchasePath.Enums.MessageBoxType.Warning:
          return 1;

        case Kobo.PurchasePath.Enums.MessageBoxType.Error:
          return 2;

        case Kobo.PurchasePath.Enums.MessageBoxType.Success:
          return 3;

        default:
          throw new Error("Unsupported message box type: " + type);
      }
    },
    ConvertPaymentTypeToBraintreeLocalPaymentType: function ConvertPaymentTypeToBraintreeLocalPaymentType(type) {
      switch (type) {
        case Kobo.PurchasePath.Enums.PaymentType.BraintreeSofort:
          return Kobo.PurchasePath.Enums.BraintreeLocalPaymentType.SOFORT;

        case Kobo.PurchasePath.Enums.PaymentType.BraintreeBancontact:
          return Kobo.PurchasePath.Enums.BraintreeLocalPaymentType.Bancontact;

        default:
          throw new Error("Unsupported braintree local payment type: " + type);
      }
    },
    ParsePurchaseOnWebPollingStatusFromServer: function ParsePurchaseOnWebPollingStatusFromServer(status) {
      switch (status) {
        case Kobo.PurchasePath.Enums.ContinuePurchaseOnWebPollingStatus.Pending:
          return 1;

        case Kobo.PurchasePath.Enums.ContinuePurchaseOnWebPollingStatus.HasAccessedWebPurchasePath:
          return 2;

        case Kobo.PurchasePath.Enums.ContinuePurchaseOnWebPollingStatus.Error:
          return 3;

        case Kobo.PurchasePath.Enums.ContinuePurchaseOnWebPollingStatus.TokenExpired:
          return 4;

        case Kobo.PurchasePath.Enums.ContinuePurchaseOnWebPollingStatus.PaymentHasStarted:
          return 5;

        default:
          throw new Error("Unsupported continue purchase on web Polling Status: " + status);
      }
    },
    ParsePurchaseOnWebTokenValidationResultStatusFromServer: function ParsePurchaseOnWebTokenValidationResultStatusFromServer(status) {
      switch (status) {
        case 1:
          return Kobo.PurchasePath.Enums.PurchaseOnWebTokenValidationResultStatus.Redirect;

        case 2:
          return Kobo.PurchasePath.Enums.PurchaseOnWebTokenValidationResultStatus.Failure;

        case 3:
          return Kobo.PurchasePath.Enums.PurchaseOnWebTokenValidationResultStatus.TokenDoesNotBelongToUser;

        default:
          throw new Error("Unsupported purchaseonweb token validation result status: " + status);
      }
    }
  });
})();