"use strict";

/*global Kobo,resources*/
Kobo.Spinner = function () {
  'use strict';

  return {
    showSpinner: function showSpinner($container) {
      $container.css({
        'background-color': 'white'
      });
      $container.addClass('ajax-spinner', true);
    },
    showSuperSpinner: function showSuperSpinner($container) {
      $container.css({
        'background-color': 'white'
      });
      $container.addClass('ajax-spinner', true);
    },
    hideSuperSpinner: function hideSuperSpinner($container) {
      $container.css({
        'background-color': 'white'
      });
      $container.addClass('ajax-spinner', true);
    },
    hideSpinner: function hideSpinner($container) {
      $container.css({
        'background-color': ''
      });
      $container.removeClass('ajax-spinner', false);
    },
    showSpinnerOverlay: function showSpinnerOverlay($container) {
      $container.addClass('ajax-spinner-overlay', true);
    },
    hideSpinnerOverlay: function hideSpinnerOverlay($container) {
      $container.removeClass('ajax-spinner-overlay', false);
    }
  };
}();