"use strict";

/*global $ */
(function () {
  var overlayElement = $('#setup-later-overlay'),
      cancelBtn = $('#setup-later-cancel-btn'),
      setupLaterBtn = $('#setup-Later-Button'),
      smallSizeScreenWidth = 568,
      leftHalfWidth = 525;

  function _setupScreenWidthResizeEventHanlder() {
    window.onresize = function () {
      _setupLaterBtnDynamicallyAlignWidget();
    };
  }

  function _setupLaterBtnDynamicallyAlignWidget() {
    var screenWidth = screen.width;

    if (screenWidth > smallSizeScreenWidth) {
      setupLaterBtn.css('margin-right', leftHalfWidth - setupLaterBtn.width());
    } else {
      setupLaterBtn.css('margin-right', 0);
    }
  }

  function _setupButtonsHandler() {
    cancelBtn.on('click', function () {
      overlayElement.css('visibility', 'hidden');
    });
    setupLaterBtn.on('click', function () {
      overlayElement.css('visibility', 'visible');
    });
  }

  function _init() {
    _setupButtonsHandler();

    _setupLaterBtnDynamicallyAlignWidget();

    _setupScreenWidthResizeEventHanlder();
  }

  _init();
})();