"use strict";

(function () {
  "use strict";

  var Kobo = window.Kobo || {};
  Kobo.Enums = Kobo.Enums || {};
  Kobo.Enums.ShoppingCart = {
    ProductType: {
      Unknown: 'Unknown',
      Book: 'Book',
      Subscription: 'Subscription',
      Issue: 'Issue',
      GiftCard: 'GiftCard',
      Publication: 'Publication',
      Stack: 'Stack',
      StoreCredit: 'StoreCredit',
      Ereader: 'Ereader',
      KwlAuthorMerch: 'KwlAuthorMerch',
      KoboLoveMembership: 'KoboLoveMembership',
      BookSubscription: 'BookSubscription',
      Audiobook: 'Audiobook',
      AudiobookCreditPack: 'AudiobookCreditPack',
      AudiobookSubscription: 'AudiobookSubscription'
    },
    ParseProductTypeFromServer: function ParseProductTypeFromServer(type) {
      switch (type) {
        case 0:
          return Kobo.Enums.ShoppingCart.ProductType.Unknown;

        case 1:
          return Kobo.Enums.ShoppingCart.ProductType.Book;

        case 2:
          return Kobo.Enums.ShoppingCart.ProductType.Subscription;

        case 3:
          return Kobo.Enums.ShoppingCart.ProductType.Issue;

        case 4:
          return Kobo.Enums.ShoppingCart.ProductType.GiftCard;

        case 5:
          return Kobo.Enums.ShoppingCart.ProductType.Publication;

        case 6:
          return Kobo.Enums.ShoppingCart.ProductType.Stack;

        case 7:
          return Kobo.Enums.ShoppingCart.ProductType.StoreCredit;

        case 8:
          return Kobo.Enums.ShoppingCart.ProductType.Ereader;

        case 9:
          return Kobo.Enums.ShoppingCart.ProductType.KwlAuthorMerch;

        case 10:
          return Kobo.Enums.ShoppingCart.ProductType.KoboLoveMembership;

        case 11:
          return Kobo.Enums.ShoppingCart.ProductType.BookSubscription;

        case 12:
          return Kobo.Enums.ShoppingCart.ProductType.Audiobook;

        case 13:
          return Kobo.Enums.ShoppingCart.ProductType.AudiobookSubscription;

        case 14:
          return Kobo.Enums.ShoppingCart.ProductType.AudiobookCreditPack;

        default:
          throw new Error("Unsupported product type: " + type);
      }
    }
  }; // Enums map to ShoppingCartSource

  Kobo.Utilities.assignToNamespace('Kobo.Enums.ShoppingCart.Source', Kobo.Enum.define({
    Unknown: ['Unknown', 0],
    ItemDetails: ['ItemDetails', 1],
    Carousel: ['Carousel', 2],
    CartRecos: ['CartRecos', 3],
    Wishlist: ['Wishlist', 4],
    AddSeries: ['AddSeries', 5]
  }));
})();