"use strict";

var BotTracker = {
  // must set ajax handler before using trackEvent()
  ajaxHandler: "/Tracking/RecordTracking",
  ajaxBotHandler: "/Tracking/VerifyHuman",
  schkCookieName: "_schk",
  sessionCookieName: "sessionId",
  sessionIdKey: "sessionid",
  parseComplexCookie: function parseComplexCookie(values) {
    var result = {};
    var valuesSplit = values.split('&');

    for (var i = 0, l = valuesSplit.length; i < l; i++) {
      var valueSplit = valuesSplit[i].split('=');

      if (valueSplit.length == 2) {
        result[valueSplit[0]] = valueSplit[1];
      }
    }

    return result;
  },
  getValueFromComplexCookie: function getValueFromComplexCookie(key, complexCookieValue) {
    var resultValue = null;
    var complextCookieValue = BotTracker.parseComplexCookie(complexCookieValue);

    if (complextCookieValue !== undefined) {
      resultValue = complextCookieValue[key];
    }

    return resultValue;
  },
  addMinutes: function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  },
  addDays: function addDays(date, days) {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  },
  set_schkCookieValue: function set_schkCookieValue(value) {
    Cookies.set(BotTracker.schkCookieName, value, {
      expires: BotTracker.addDays(new Date(), 7),
      path: '/'
    });
  },
  verifyBot: function verifyBot() {
    var sessionId = null;
    var sessionCookieValue = Cookies.get(BotTracker.sessionCookieName);

    if (sessionCookieValue === undefined) {
      return;
    }

    sessionId = BotTracker.getValueFromComplexCookie(BotTracker.sessionIdKey, sessionCookieValue);
    var schk = Cookies.get(BotTracker.schkCookieName);

    if (schk === undefined || schk !== sessionId) {
      var a = Math.floor(Math.random() * 11);
      var b = Math.floor(Math.random() * 11);
      var ajaxArgs = {
        a: a,
        b: b,
        c: a + b
      };
      Kobo.$.ajax({
        context: BotTracker,
        type: "POST",
        contentType: 'application/json: charset=utf-8',
        url: BotTracker.ajaxBotHandler,
        data: JSON.stringify(ajaxArgs),
        dataType: 'json',
        success: function success() {
          BotTracker.set_schkCookieValue(sessionId);
        }
      });
    } else {
      BotTracker.set_schkCookieValue(sessionId);
    }
  }
};