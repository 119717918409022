"use strict";

/*globals Kobo */

/*

A smooth scroll utility that checks if click was from a smooth-scroll anchor. If it was it finds the target element and animates to it.
This function uses event delegation to determine whether the click was from a smooth-scroll link, this is to account for dynamic elements that
are not in the dom when the page/ gizmo loads.

example implementation:

    <a class="smooth-scroll" href="#location-1">location 1 </a>
    <div id="location-1"></div>

*/
(function () {
  "use strict";

  var $pageRoot = Kobo.$('body'); // click is bound to page root so that elements not available on page on load will still work correctly.

  $pageRoot.on('click', function (e) {
    var $trigger = Kobo.$(e.target);
    var smoothScrollLinkClicked = $trigger.hasClass('smooth-scroll'); // if link was a smooth-scroll link find the target and animate to it.

    if (smoothScrollLinkClicked) {
      var $animationRoot = Kobo.$('html,body');
      var $targetEl = Kobo.$($trigger.attr('href'));
      e.preventDefault();

      if ($targetEl.length) {
        $animationRoot.animate({
          scrollTop: $targetEl.offset().top
        }, 400);
      }
    }
  });
})();