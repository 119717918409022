"use strict";

(function () {
  Kobo.Utilities.assignToNamespace('Kobo.Controls.Dialog.ButtonVM', ButtonVM);

  function ButtonVM(options) {
    var self = this;
    var settings = Kobo.$.extend({
      label: null,
      action: null,
      closeOnClick: true
    }, options);
    this.label = settings.label;
    this.notifyClickEvent = new ko.subscribable();

    this.click = function () {
      if (settings.action) {
        settings.action();
      }

      self.notifyClickEvent.notifySubscribers();
    };

    this.closeOnClick = settings.closeOnClick;
  }
})();