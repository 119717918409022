"use strict";

/*global Kobo,ko,Modernizr*/
Kobo.Utilities.Images = function () {
  "use strict";

  var makeSlugUrlSafe = function makeSlugUrlSafe(slug) {
    var safeSlug = slug.replace(/[\s | \\ | \. | \* | \? | \^ | \$ | \[ | \] | \( | \) | \| | \{ | \} | \/ | \' | \# | \n | \r | \t]/g, '+');
    return encodeURI(safeSlug);
  },
      defaultJpgQuality = 75;

  return {
    /**
     * generateImageUrl to generate an image url
     * Important: Maps to GetCoverImageUrl(Guid imageId, int width, int height, bool isMonochrome, string slug, ImageFormatType imageFormat)
     * @param {string} imageId
     * @param {int} MaxWidth
     * @param {int} maxHeight
     * @param {boolean} isMonochrome
     * @param {string} nameSlug
     * @param {string} format
     * @param {int} [quality]
     * @returns {string}
     */
    generateImageUrl: function generateImageUrl(imageId, MaxWidth, maxHeight, isMonochrome, nameSlug, format, quality) {
      if (isNaN(quality)) {
        quality = defaultJpgQuality;
      }

      if (nameSlug) {
        return Kobo.Utilities.stringFormat(Kobo.configuration.imageUrlTemplate, imageId, MaxWidth, maxHeight, isMonochrome, makeSlugUrlSafe(nameSlug), format);
      } else {
        return Kobo.Utilities.stringFormat(Kobo.configuration.imageUrlTemplate, imageId, MaxWidth, maxHeight, isMonochrome, format);
      }
    },
    getAssetImageUrl: function () {
      var rootUrl = '',
          imagePath = 'Images/',
          defaultImageName = 'empty_book_cover.jpg',
          emptyBookCoverImgNameFullUrl = '',
          scriptSrc;

      if (window.DynamicConfiguration && window.DynamicConfiguration.assetsRoot) {
        rootUrl = window.DynamicConfiguration.assetsRoot;
      } else {
        try {
          scriptSrc = Kobo.$('script1[src*="Gizmo.js"]').attr('src') || Kobo.$('script1[src*="kobo.min.js"]').attr('src') || Kobo.$('script1[src*="modernizr"]').attr('src');
          rootUrl = scriptSrc.match(/^(http[s]?\:\/\/[\w\.\-\d]*\/)/)[1];
        } catch (e) {
          if (Kobo._error) {
            Kobo._error.error('', 'Kobo.Utilities.Images', Kobo.Utilities.Images, {
              msg: 'Cannot detect root url for assets: ' + scriptSrc + ', ' + rootUrl,
              e: e
            });
          }
        }
      }

      return function (imageName) {
        var _imageName = !!imageName ? imageName : defaultImageName;

        return rootUrl + imagePath + _imageName;
      };
    }(),
    getDefaultBookCover: function getDefaultBookCover() {
      var defaultBookCover = 'empty_book_cover.jpg';
      return Kobo.Utilities.Images.getAssetImageUrl(defaultBookCover);
    }
  };
}();