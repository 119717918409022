"use strict";

/*globals Kobo*/
(function () {
  'use strict';

  Kobo.Validate = function (form, model) {
    var canSubmit = true,
        focusField,
        fields = form.find(':input'),
        firstErrorField,
        i,
        field;

    focusField = function focusField(field) {
      if (field && field.length) {
        field.focus();
      }
    };

    for (i = 0; i < fields.length; i++) {
      field = fields[i];

      if (model[field.name] && model[field.name].isValid && !model[field.name].isValid()) {
        if (!firstErrorField) {
          firstErrorField = Kobo.$(field);
        }
        /**
         * There is a bug in the knockout validation plugin, if the value
         * is empty when submitted to the server the error message doesn't
         * get displayed unless you also set isModified.
         */


        model[field.name].isModified(true);
        canSubmit = false;
      }

      focusField(firstErrorField);
    }

    return canSubmit;
  };
})();