"use strict";

/*globals Kobo*/
(function () {
  'use strict';

  var emptyGuid = '00000000-0000-0000-0000-000000000000';
  Kobo.Guid = {
    empty: emptyGuid,
    isNullOrEmpty: function isNullOrEmpty(guid) {
      return !guid || guid === emptyGuid;
    },
    isEmpty: function isEmpty(guid) {
      return guid === emptyGuid;
    }
  };
})();