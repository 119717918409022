"use strict";

/*globals ko*/

/**
 * When navigating using the back button, the browser will try to restore the state of the previous page via autocomplete.
 * Input fields get updated with previous values, but the knockout view model doesn't get updated. This ensures that any 
 * autocompletion input is reflected in the view model.
 */
ko.bindingHandlers.setValueFromAutocomplete = ko.bindingHandlers.setValueFromAutocomplete || {
  /**
   * 
   * @param {HTMLElement} element
   * @param {() => any} _
   * @param {KnockoutAllBindingsAccessor} allBindings
   */
  init: function init(element, _, allBindings) {
    var valueBinding = allBindings.get('value');
    setTimeout(function () {
      if (element.value) {
        valueBinding(element.value);
      }
    }, 0);
  }
};