"use strict";

/*globals Kobo*/
(function () {
  Kobo.Utilities.assignToNamespace('Kobo.Controls.MessageBox.MessageBoxType', Kobo.Enum.define({
    Unknown: ['unknown', 0],
    Success: ['success', 1],
    Warning: ['warning', 2],
    Error: ['error', 3]
  }));
})();