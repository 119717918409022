"use strict";

/*global Kobo,$,ko */

/**
 * Manages library actions like adding books to a user's library
 */
// singleton
Kobo._librarian = function () {
  'use strict';

  var onAdd,
      onAddResponse,
      addToReadingListUnderSubscription,
      init,
      add,
      plusReadingListToast = window.DynamicConfiguration.resourceStrings.plusReadingListToast,
      plusReadingListSkippedToast = window.DynamicConfiguration.resourceStrings.plusReadingListSkippedToast,
      errorAddingToReadingListToast = window.DynamicConfiguration.resourceStrings.errorAddingToReadingListToast;

  onAdd = function onAdd(eventName, data) {
    add({
      actionUrl: data.detail.actionUrl,
      completeCallback: data.detail.completeCallback,
      ageVerificationRequired: data.detail.ageVerificationRequired
    });
  };

  add = function add(data) {
    if (Kobo._AgeVerification.isRequired(data.ageVerificationRequired)) {
      data.caller = "library::add";

      Kobo._mediator.fire('ageVerificationCheck', data);
    } else {
      var successResult = {};
      Kobo.Ajax({
        method: 'POST',
        url: data.actionUrl,
        success: function success(result) {
          successResult = result;
          onAddResponse(result);
        },
        error: function error() {
          Kobo._mediator.fire("toast::error", {
            message: window.DynamicConfiguration.resourceStrings.GenericErrorMessage
          });
        },
        complete: function complete() {
          if (data.completeCallback) {
            data.completeCallback(successResult);
          }
        }
      });
    }
  };

  onAddResponse = function onAddResponse(data) {
    var message = data.message || data.Message;

    if (data.result === 'success') {
      Kobo._mediator.fire("toast::info", {
        message: message
      });
    } else if (data.result === 'redirect') {
      Kobo._tracker.sendResultsSynchronously();

      Kobo.Utilities.navigateTo(data.redirectUrl);
    } else {
      Kobo._mediator.fire("toast::error", {
        message: message
      });
    }
  };

  addToReadingListUnderSubscription = function addToReadingListUnderSubscription(e, d) {
    Kobo.$.ajax({
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      url: d.detail.actionUrl,
      data: JSON.stringify({
        productId: d.detail.productId,
        subscriptionId: d.detail.subscriptionId
      })
    }).done(function (data) {
      if (data !== null && data.result === "success") {
        if (data.message === "skipped") {
          Kobo._mediator.fire("toast::info", {
            message: plusReadingListSkippedToast
          });
        } else {
          Kobo._mediator.fire("toast::info", {
            message: plusReadingListToast
          });
        }

        if (d.detail.completeCallback) {
          d.detail.completeCallback(data);
        }

        return;
      }

      Kobo._mediator.fire("toast::error", {
        message: errorAddingToReadingListToast
      });
    }).fail(function () {
      Kobo._mediator.fire("toast::error", {
        message: errorAddingToReadingListToast
      });
    });
  };

  init = function init() {
    Kobo._mediator.register('library::add');

    Kobo._mediator.register('library::addRemoved');

    Kobo._mediator.register('library::addPreview');

    Kobo._mediator.register('ageVerificationCheck');

    Kobo._mediator.subscribe('library::add', onAdd);

    Kobo._mediator.subscribe('library::addRemoved', onAdd);

    Kobo._mediator.subscribe('library::addPreview', onAdd);

    Kobo._mediator.register('productAction::addToReadingListUnderSubscription');

    Kobo._mediator.subscribe('productAction::addToReadingListUnderSubscription', addToReadingListUnderSubscription);
  };

  init();
}();